/*
@font-face {
 font-family: "Example";
 src: url("../fonts/Example.ttf") format("truetype");
 font-weight: 900;
 font-style: none;
}

@font-face {
 font-family: "Example";
 src: url("../fonts/Example-Black.ttf") format("truetype");
 font-weight: 900;
 font-style: none;
}
*/
